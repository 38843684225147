import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Cookies } from "../constants/Cookies";
import {
  MyProjectModel,
  MyRequestModel,
  ExternalProjectModel,
  ProjectModel,
} from "../models/service/ServiceInfo";
import {
  CheckExpertModel,
  ClientStatModel,
  ExpertModel,
  ExpertStatModel,
} from "../models/service/ExpertModel";
import GlobalEnvironments from "../modules/globalEnvironment/GlobalEnvironment";
import { util } from "./util";
import { TaskFormModel } from "../models/service/TaskModel";
import {
  ServiceRequestFormModel,
  ServiceRequestModel,
} from "../models/service/ServiceRequestModel";
import {
  ServiceCategoryModel,
  ServiceModel,
} from "../models/service/ServiceModel";
import { ProjectReviewFormModel } from "../models/service/ProjectReviewModel";
import { ProjectDetailModel } from "../models/service/ProjectModel";

export const serviceApi = {
  //서비스 카테고리 리스트
  getServiceCategories: async (): Promise<
    AxiosResponse<ServiceCategoryModel[]>
  > => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/categories`,
      config
    );
  },
  //전문가 리스트
  getExperts: async (): Promise<AxiosResponse<ExpertModel[]>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/experts`,
      config
    );
  },
  //전문가 상세
  getExpert: async (id: number): Promise<AxiosResponse<ExpertModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/experts/${id}`,
      config
    );
  },
  //전문가 체크
  checkExpert: async (): Promise<AxiosResponse<CheckExpertModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/experts/check_expert`,
      config
    );
  },
  //서비스 신청 상세
  getServiceRequest: async (
    id: string
  ): Promise<AxiosResponse<ServiceRequestModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/requests/${id}`,
      config
    );
  },

  //프로젝트 전체 요청 (내것이 아닌)
  getExternalProjects: async (): Promise<
    AxiosResponse<ProjectDetailModel[]>
  > => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/projects`,
      config
    );
  },

  //프로젝트 전체 요청 (내것이 아닌)
  getExternalProject: async (
    id: number
  ): Promise<AxiosResponse<ProjectDetailModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/projects/${id}`,
      config
    );
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     const mockResponse: AxiosResponse<ProjectModel> = {
    //       data: {
    //         id: 1,
    //         title: "서울 아파트 설계 프로젝트",
    //         service: "실시설계 용역",
    //         status: "in_progress",
    //         date: "2023-06-15",
    //         budget: 5000000,
    //         currentCost: 3000000,
    //         description:
    //           "서울 강남구 신축 아파트 단지의 실시설계 프로젝트입니다.",
    //         client: "강남 건설",
    //         expert: "김설계",
    //       },
    //       status: 200,
    //       statusText: "OK",
    //       headers: {},
    //       config,
    //     };
    //     resolve(mockResponse);
    //   }, 200); // 0.5초 지연
    // });
  },

  //서비스 신청 리스트 (전문가가 봐야할 신청 리스트)
  getServiceRequests: async (): Promise<
    AxiosResponse<ServiceRequestModel[]>
  > => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/requests/unmatched/`,
      config
    );
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     const mockResponse: AxiosResponse<ServiceRequestModel[]> = {
    //       data: [
    //         {
    //           id: 1729040105430,
    //           service: "설계변경 용역",
    //           title: "설계 용역 의뢰합니다22",
    //           status: 1,
    //           date: "2024-10-16",
    //           client: "chd",
    //           description: "234234234",
    //           expectedCompletionDate: "2024-10-03",
    //         },
    //         {
    //           id: 1729665163832,
    //           service: "설계변경 용역",
    //           title: "설계 용역 의뢰합니다",
    //           status: 3,
    //           date: "2024-10-23",
    //           client: "chd",
    //           description: "123123123123123",
    //           expectedCompletionDate: "2024-10-03",
    //         },
    //       ],
    //       status: 200,
    //       statusText: "OK",
    //       headers: {},
    //       config,
    //     };
    //     resolve(mockResponse);
    //   }, 200); // 0.5초 지연
    // });
  },
  getMyClientStat: async (): Promise<AxiosResponse<ClientStatModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/my_clients/stat`,
      config
    );
  },
  getMyExpertStat: async (): Promise<AxiosResponse<ExpertStatModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/my_experts/stat`,
      config
    );
  },
  //서비스 카테고리 상세 (옵션 포함)
  getServiceCategory: async (
    id: number
  ): Promise<AxiosResponse<ServiceModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/categories/${id}/details`,
      config
    );
  },
  //서비스 신청
  postServiceRequest: async (
    serviceReqeust: ServiceRequestFormModel
  ): Promise<AxiosResponse<any>> => {
    try {
      const key = util.getCookie(Cookies.authKey);
      const config = {
        headers: {
          Authorization: `Token ${key}`,
          "Content-Type": "application/json",
        },
      };
      return await axios.post(
        `${GlobalEnvironments.getApiUrl()}service/requests/`,
        serviceReqeust,
        config
      );
    } catch (error) {
      let code = undefined;
      let message = undefined;
      if (axios.isAxiosError(error)) {
        code = error.code;
        const data = error.response?.data;
        const firstKey = Object.keys(data)[0]; // 'budget'
        const firstValue = data[firstKey][0];
        message = firstValue;
      } else {
        code = 500;
        message = "서버오류입니다. 1:1문의를 이용해주세요.";
      }

      return {
        data: message,
        status: +code!,
        headers: {},
        config: {},
      } as AxiosResponse<any>;
    }
  },
  // 서비스신청 삭제
  deleteServiceRequest: async (id: number): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}service/requests/${id}/`,
      config
    );
  },

  // 내가 신청한 프로젝트
  getMyProjectsByClient: async (): Promise<
    AxiosResponse<ProjectDetailModel[]>
  > => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/projects/my_client_projects/`,
      config
    );
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     const mockResponse: AxiosResponse<MyProjectModel[]> = {
    //       data: clientDummyData,
    //       status: 200,
    //       statusText: "OK",
    //       headers: {},
    //       config,
    //     };
    //     resolve(mockResponse);
    //   }, 200); // 0.5초 지연
    // });
  },

  //내가 신청한 서비스
  getMyServices: async (): Promise<AxiosResponse<ServiceRequestModel[]>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/requests`,
      config
    );
  },

  //내가 진행중인 프로젝트
  getMyProjectsByExpert: async (): Promise<
    AxiosResponse<ProjectDetailModel[]>
  > => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/projects/my_expert_projects`,
      config
    );
  },

  // 프로젝트 단건 조회
  getProject: async (
    id: number
  ): Promise<AxiosResponse<ProjectDetailModel>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}service/projects/${id}`,
      config
    );
    // },
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     const mockResponse: AxiosResponse<ProjectDetailModel> = {
    //       data: {
    //         id: 1,
    //         title: "서울 아파트 설계 프로젝트",
    //         description: "서울 강남구 신축 아파트 단지 설계 프로젝트입니다.",
    //         status: "pending",
    //         progress: 100,
    //         pending_approvals: 0,
    //         service_cost: 250000000,
    //         client: {
    //           email: "ㅇㅇㅇ@ㅇㅇㅇ.com",
    //           id: 1,
    //           username: "서울 개발",
    //         },
    //         expert: {
    //           email: "ㅇㅇㅇ@ㅇㅇㅇ.com",
    //           id: 1,
    //           username: "서울 개발",
    //         },
    //         tasks: [
    //           {
    //             id: 1,
    //             title: "태스크 1",
    //             description: "태스크 설명",
    //             status: "pending",
    //             dueDate: "2024-11-03",
    //             assignedTo: "",
    //             comments: [
    //               {
    //                 author: { id: 1, email: "", username: "김전문" },
    //                 content: "이거 저거",
    //                 date: "2024-11-02",
    //               },
    //             ],
    //             attachments: [],
    //           },
    //           {
    //             id: 2,
    //             title: "태스크 2",
    //             description: "태스크 설명",
    //             status: "in_progress",
    //             dueDate: "2024-11-03",
    //             assignedTo: "",
    //             comments: [
    //               {
    //                 author: { id: 1, email: "", username: "김전문" },
    //                 content: "이거 저거",
    //                 date: "2024-11-02",
    //               },
    //             ],
    //             attachments: [],
    //           },
    //           {
    //             id: 3,
    //             title: "태스크 3",
    //             description: "태스크 설명",
    //             status: "waiting_approval",
    //             dueDate: "2024-11-03",
    //             assignedTo: "",
    //             comments: [
    //               {
    //                 author: { id: 1, email: "", username: "김전문" },
    //                 content: "이거 저거",
    //                 date: "2024-11-02",
    //               },
    //             ],
    //             attachments: [],
    //           },
    //           {
    //             id: 4,
    //             title: "태스크 4",
    //             description: "태스크 설명",
    //             status: "approved",
    //             dueDate: "2024-11-03",
    //             assignedTo: "",
    //             comments: [
    //               {
    //                 author: { id: 1, email: "", username: "김전문" },
    //                 content: "이거 저거",
    //                 date: "2024-11-02",
    //               },
    //             ],
    //             attachments: [],
    //           },
    //         ],
    //         service_request: {
    //           id: 13,
    //           title: "최요셉",
    //           description: "테스트",
    //           budget: 100000000,
    //           current_cost: 100000000,
    //           status: "pending",
    //           desired_completion_date: "2024-11-30",
    //           created_at: "2024-11-10T10:43:53.902313",
    //           updated_at: "2024-11-10T10:43:53.902336",
    //           selected_option: "A",
    //           service: {
    //             id: 7,
    //             title: "기성 용역",
    //             icon: "payment",
    //             description: "정확한 기성 산정 및 관련 서류 작성 서비스",
    //             order: 7,
    //             details: [
    //               {
    //                 id: 32,
    //                 section: "details",
    //                 key: "1",
    //                 item: "갑지(표지)",
    //                 option_a: true,
    //                 option_b: false,
    //                 order: 1,
    //               },
    //               {
    //                 id: 33,
    //                 section: "details",
    //                 key: "2",
    //                 item: "원가계산서",
    //                 option_a: true,
    //                 option_b: false,
    //                 order: 2,
    //               },
    //               {
    //                 id: 34,
    //                 section: "details",
    //                 key: "3",
    //                 item: "기성내역서",
    //                 option_a: true,
    //                 option_b: false,
    //                 order: 3,
    //               },
    //             ],
    //             prices: [
    //               {
    //                 id: 14,
    //                 range: "내역서시트 1개 이하",
    //                 option_a: "20만원 ↑",
    //                 option_b: "",
    //                 order: 1,
    //               },
    //               {
    //                 id: 15,
    //                 range: "내역서시트 2개 이상",
    //                 option_a: "30만원 ↑",
    //                 option_b: "",
    //                 order: 2,
    //               },
    //             ],
    //           },
    //           email: "expert@ddukeong.com",
    //           company_name: "회사명",
    //           contact: "01033759575",
    //           client: {
    //             id: 7110,
    //             username: "expert@ddukeong.com",
    //             email: "expert@ddukeong.com",
    //           },
    //           expert: null,
    //           matching_experts: [],
    //           files: [
    //             {
    //               id: 3,
    //               file_url:
    //                 "https://ddukeong-blob.s3.ap-northeast-2.amazonaws.com/ddusul/%E1%84%92%E1%85%A1%E1%84%85%E1%85%AE_241110_10_43.jpeg",
    //               file_name:
    //                 "%E1%84%92%E1%85%A1%E1%84%85%E1%85%AE_241110_10_43.jpeg",
    //               file_size: 0,
    //               uploaded_at: "2024-11-10T10:43:53.907551",
    //             },
    //             {
    //               id: 4,
    //               file_url:
    //                 "https://ddukeong-blob.s3.ap-northeast-2.amazonaws.com/ddusul/%E1%84%90%E1%85%A2%E1%86%BC%E1%84%89%E1%85%AE%E1%86%AB_241110_10_43.jpeg",
    //               file_name:
    //                 "%E1%84%90%E1%85%A2%E1%86%BC%E1%84%89%E1%85%AE%E1%86%AB_241110_10_43.jpeg",
    //               file_size: 0,
    //               uploaded_at: "2024-11-10T10:43:53.910849",
    //             },
    //           ],
    //           service_category: {
    //             id: 7,
    //             title: "기성 용역",
    //             icon: "",
    //             description: "정확한 기성 산정 및 관련 서류 작성 서비스",
    //           },
    //         },
    //         created_at: "",
    //         updated_at: "",
    //       },
    //       status: 200,
    //       statusText: "OK",
    //       headers: {},
    //       config,
    //     };
    //     resolve(mockResponse);
    //   }, 200); // 0.5초 지연
    // });
  },
  // 프로젝트 등록
  postProject: async (
    service_request_id: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/create_from_request/`,
      { service_request_id },
      config
    );
  },

  // 프로젝트 리뷰 등록
  postProjectReview: async (
    projectId: number,
    reviewForm: ProjectReviewFormModel
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/reviews/`,
      {
        content: reviewForm.content,
        rating: reviewForm.rating,
        project: projectId,
      },
      config
    );
  },

  // 프로젝트 내 작업 완료
  completeProjectTask: async (
    projectId: number,
    taskId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/complete_task`,
      { taskId },
      config
    );
  },

  // 프로젝트 내 작업 등록
  postProjectTask: async (
    projectId: number,
    taskForm: TaskFormModel
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/`,
      taskForm,
      config
    );
  },

  // 프로젝트 내 작업 삭제
  deleteProjectTask: async (
    projectId: number,
    taskId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/${taskId}`,
      config
    );
  },

  // 프로젝트 내 작업 승인
  proceedProjectTask: async (
    projectId: number,
    taskId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/${taskId}/proceed/`,
      {},
      config
    );
  },

  // 프로젝트 내 작업 승인취소
  cancelProjectTask: async (
    projectId: number,
    taskId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/${taskId}/cancel/`,
      {},
      config
    );
  },

  // 프로젝트 내 작업 반려
  rejectProjectTask: async (
    projectId: number,
    taskId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/${taskId}/reject/`,
      {},
      config
    );
  },

  // 전문가가 서비스에 참여할 때
  joinService: async (
    serviceRequestId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/requests/${serviceRequestId}/join/`,
      {},
      config
    );
  },

  // 의뢰자가 전문가 선택할 때
  selectExpert: async (
    serviceRequestId: number,
    expertId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/requests/${serviceRequestId}/select_expert/`,
      { expertId },
      config
    );
  },

  // 의뢰자가 전화상담까지 완료 했을 때
  completeCall: async (serviceId: number): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/requests/${serviceId}/complete_call/`,
      {},
      config
    );
  },

  //진행률, 용역비용 수정
  updateProject: async (
    projectId: number,
    progress?: number,
    serviceCost?: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.put(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/update_progress/`,
      { progress, serviceCost },
      config
    );
  },

  postComment: async (
    projectId: number,
    taskId: number,
    content: string
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/${taskId}/comments/`,
      { content },
      config
    );
  },
  deleteComment: async (
    projectId: number,
    taskId: number,
    commentId: number
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/${taskId}/comments/${commentId}/`,
      config
    );
  },
  updateTask: async (
    projectId: number,
    taskId: number,
    title?: string,
    description?: string,
    deadline?: string,
    file_urls?: string[]
  ): Promise<AxiosResponse<any>> => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
        "Content-Type": "application/json",
      },
    };
    return await axios.put(
      `${GlobalEnvironments.getApiUrl()}service/projects/${projectId}/tasks/${taskId}/`,
      { title, description, deadline, file_urls },
      config
    );
  },
};
