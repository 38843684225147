import React, { useState } from "react";
import { List, Input, Select, Card, Avatar, Rate } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./ExpertList.module.scss";
import { useEffect } from "react";
import { serviceApi } from "../../config/serviceApi";
import { ExpertModel } from "../../models/service/ExpertModel";
import { ServiceCategoryModel } from "../../models/service/ServiceModel";

const { Search } = Input;
const { Option } = Select;

const ExpertList: React.FC = () => {
  const [experts, setExperts] = useState<ExpertModel[]>([]);
  const [serviceCategories, setServiceCategories] = useState<
    ServiceCategoryModel[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [specialty, setSpecialty] = useState("all");

  useEffect(() => {
    async function fetch() {
      const res = await serviceApi.getExperts();
      const categoryRes = await serviceApi.getServiceCategories();
      setExperts(res.data);
      setServiceCategories(categoryRes.data);
    }
    fetch();
  }, []);

  const expertFilters = () => {
    console.log({ searchTerm, specialty });
    if (searchTerm != "" && specialty != "all") {
      return experts
        .filter((expert) => expert.specialty.includes(specialty))
        .filter((expert) => expert.nickname.includes(searchTerm));
    }
    if (searchTerm != "") {
      return experts.filter((expert) => expert.nickname.includes(searchTerm));
    }
    if (specialty != "all") {
      return experts.filter((expert) => expert.specialty.includes(specialty));
    }
    return experts;
  };

  return (
    <div className={styles.expertListContainer}>
      <h1>전문가 목록</h1>
      <div className={styles.searchContainer}>
        <Search
          placeholder="전문가 이름 검색"
          onSearch={(value) => {
            setSearchTerm(value);
          }}
          style={{ width: 200 }}
        />
        <Select
          defaultValue="all"
          style={{ width: 120 }}
          onChange={(value) => setSpecialty(value)}
        >
          <Option value="all">모든 분야</Option>
          {serviceCategories.map((category) => (
            <Option key={category.id + category.title} value={category.title}>
              {category.title}
            </Option>
          ))}
        </Select>
      </div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={expertFilters()}
        renderItem={(expert) => (
          <List.Item>
            <Link to={`/experts/${expert.id}`}>
              <Card hoverable className={styles.expertCard}>
                <Card.Meta
                  avatar={
                    <Avatar
                      size={64}
                      src={expert.thumbnail}
                      icon={<UserOutlined />}
                    />
                  }
                  title={expert.nickname}
                  description={
                    <>
                      <p>{JSON.parse(expert.specialty).join(",")}</p>
                      <p>경력: {expert.experience}</p>
                      <Rate disabled defaultValue={expert.rating} />
                      <p>{expert.description}</p>
                    </>
                  }
                />
              </Card>
            </Link>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ExpertList;
