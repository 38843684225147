import React, { useState, useEffect, useRef } from 'react';
import { List, Input, Button, Avatar, Typography } from 'antd';
import { SendOutlined, UserOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface Message {
  id: number;
  sender: string;
  content: string;
  timestamp: string;
}

interface ChatComponentProps {
  currentUser: string;
  expertId: string;
}

const ChatComponent: React.FC<ChatComponentProps> = ({ currentUser, expertId }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    // 초기 메시지 설정
    const initialMessages: Message[] = [
      { id: 1, sender: expertId, content: '안녕하세요, 무엇을 도와드릴까요?', timestamp: new Date().toISOString() },
      { id: 2, sender: currentUser, content: '프로젝트에 대해 문의하고 싶습니다.', timestamp: new Date().toISOString() },
    ];
    setMessages(initialMessages);
  }, [currentUser, expertId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      const newMessage: Message = {
        id: messages.length + 1,
        sender: currentUser,
        content: inputMessage,
        timestamp: new Date().toISOString(),
      };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInputMessage('');

      // 전문가의 자동 응답 (시뮬레이션)
      setTimeout(() => {
        const expertResponse: Message = {
          id: messages.length + 2,
          sender: expertId,
          content: '네, 어떤 점이 궁금하신가요?',
          timestamp: new Date().toISOString(),
        };
        setMessages(prevMessages => [...prevMessages, expertResponse]);
      }, 1000);
    }
  };

  return (
    <div style={{ height: '400px', display: 'flex', flexDirection: 'column' }}>
      <List
        style={{ flex: 1, overflowY: 'auto', padding: '10px' }}
        dataSource={messages}
        renderItem={(item) => (
          <List.Item style={{ justifyContent: item.sender === currentUser ? 'flex-end' : 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: item.sender === currentUser ? 'row-reverse' : 'row', alignItems: 'flex-start', maxWidth: '70%' }}>
              <Avatar icon={<UserOutlined />} style={{ marginRight: item.sender === currentUser ? 0 : '10px', marginLeft: item.sender === currentUser ? '10px' : 0 }} />
              <div>
                <Text strong>{item.sender === currentUser ? '나' : '전문가'}</Text>
                <div style={{ 
                  background: item.sender === currentUser ? '#1890ff' : '#f0f0f0', 
                  color: item.sender === currentUser ? 'white' : 'black',
                  padding: '8px 12px', 
                  borderRadius: '12px', 
                  marginTop: '4px'
                }}>
                  {item.content}
                </div>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  {new Date(item.timestamp).toLocaleTimeString()}
                </Text>
              </div>
            </div>
          </List.Item>
        )}
      />
      <div ref={messagesEndRef} />
      <div style={{ display: 'flex', padding: '10px' }}>
        <Input 
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onPressEnter={handleSendMessage}
          placeholder="메시지를 입력하세요..."
        />
        <Button type="primary" icon={<SendOutlined />} onClick={handleSendMessage} style={{ marginLeft: '10px' }}>
          전송
        </Button>
      </div>
    </div>
  );
};

export default ChatComponent;