import { useState } from "react";
import { Row, Col, Card, Statistic, List, Progress, Button, Tag } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { serviceApi } from "../../config/serviceApi";

import { getServiceStatus } from "../service/ServiceUtil";
import { pages } from "../../constants/PagePaths";
import { ServiceRequestModel } from "../../models/service/ServiceRequestModel";
import { ProjectDetailModel } from "../../models/service/ProjectModel";

// 의뢰자 대시보드 렌더링 함수
export const ClientDashboard: React.FC = () => {
  const [clientData, setClientData] = useState<ProjectDetailModel[]>([]);
  const [serviceApplications, setServiceApplications] = useState<
    ServiceRequestModel[]
  >([]);

  useEffect(() => {
    async function fetch() {
      const myClientDateRes = await serviceApi.getMyServices();
      setServiceApplications(myClientDateRes.data);
      const myProjectRes = await serviceApi.getMyProjectsByClient();
      setClientData(myProjectRes.data);
    }
    fetch();
  }, []);

  const ongoingProjects = clientData.filter(
    (p) => p.status === "ongoing"
  ).length;
  const completedProjects = clientData.filter(
    (p) => p.status === "completed"
  ).length;
  const pendingProjects = clientData.filter(
    (p) => p.status === "pending"
  ).length;
  const totalPendingApprovals = clientData.reduce(
    (sum, p) => sum + p.pending_approvals,
    0
  );

  return (
    <>
      <Row
        gutter={16}
        style={{ marginBottom: "20px", justifyContent: "start" }}
      >
        <Col span={6}>
          <Card>
            <Statistic
              title="진행 중인 프로젝트"
              value={ongoingProjects}
              prefix={<ClockCircleOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="완료된 프로젝트"
              value={completedProjects}
              prefix={<CheckCircleOutlined />}
            />
          </Card>
        </Col>
        {/* <Col span={6}>
          <Card>
            <Statistic
              title="대기 중인 프로젝트"
              value={pendingProjects}
              prefix={<ProjectOutlined />}
            />
          </Card>
        </Col> */}
        <Col span={6}>
          <Card>
            <Statistic
              title="승인 대기 중인 작업"
              value={totalPendingApprovals}
              prefix={<ExclamationCircleOutlined />}
            />
          </Card>
        </Col>
      </Row>

      <Card title="서비스 신청 현황" style={{ marginBottom: "20px" }}>
        <List
          itemLayout="horizontal"
          dataSource={serviceApplications}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Link to={`${pages.serviceApplication}/${item.id}`}>
                  <Button type="primary">상세 보기</Button>
                </Link>,
              ]}
            >
              <List.Item.Meta
                title={item.service.title}
                description={
                  <>
                    <Tag
                      color={
                        item.status === "completed"
                          ? "success"
                          : item.status === "cancelled"
                          ? "green"
                          : "processing"
                      }
                    >
                      {getServiceStatus(item.status).title}
                      {/* {item.status} */}
                    </Tag>
                    <span style={{ marginLeft: "10px" }}>
                      신청일:{" "}
                      {new Date(item.updated_at).toISOString().split("T")[0]}
                    </span>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Card>

      <Card title="내 프로젝트">
        <List
          itemLayout="horizontal"
          dataSource={clientData}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Link to={`/projects/${item.id}`}>
                  <Button type="primary">상세 보기</Button>
                </Link>,
              ]}
            >
              <List.Item.Meta
                title={
                  <Link to={`/projects/${item.id}`}>
                    {item.service_request.service.title}
                  </Link>
                }
                description={
                  <>
                    <Tag
                      color={
                        item.status === "ongoing"
                          ? "processing"
                          : item.status === "completed"
                          ? "success"
                          : "default"
                      }
                    >
                      {item.status === "ongoing"
                        ? "진행 중"
                        : item.status === "completed"
                        ? "완료"
                        : "대기 중"}
                    </Tag>
                    {item.pending_approvals > 0 && (
                      <Tag color="warning">
                        승인 대기 {item.pending_approvals}건
                      </Tag>
                    )}
                    {item.status !== "pending" && (
                      <div style={{ marginTop: "10px" }}>
                        진행률: {item.progress}%
                        <Progress
                          percent={item.progress}
                          status="active"
                          style={{ width: "200px" }}
                        />
                      </div>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};
