import { PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";

const SearchResultAside = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="search-result-aside"
      style={{ left: isOpen ? "0" : "-240px" }}
    >
      <div
        className="aside-flag"
        onClick={() => setIsOpen(!isOpen)}
        style={{ left: isOpen ? "calc(100% - 48px)" : "100%" }}
      >
        {isOpen ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.043 7.99996L43.043 40.0006L40.043 40.0006L40.043 7.99996L43.043 7.99996Z"
              fill="var(--color-primary)"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.97867 25.5006L20.4179 35.9398L18.2966 38.0612L6.52506 26.2896C5.15822 24.9228 5.15822 22.7067 6.52506 21.3399L18.2966 9.56837L20.4179 11.6897L9.60697 22.5006L34.543 22.5006L34.543 25.5006L9.97867 25.5006ZM8.54297 23.6115L8.54297 24.018C8.48558 23.889 8.48558 23.7406 8.54297 23.6115Z"
              fill="var(--color-primary)"
            ></path>
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 40.0006V8H8.5V40.0006H5.5Z"
              fill="var(--color-primary)"
            ></path>
            <path
              d="M38.9359 25.5L28.1251 36.3108L30.2465 38.4321L42.018 26.6606C43.3848 25.2938 43.3848 23.0777 42.018 21.7109L30.2465 9.93933L28.1251 12.0607L38.5645 22.5L14 22.5V25.5L38.9359 25.5Z"
              fill="var(--color-primary)"
            ></path>
          </svg>
        )}
      </div>
      <div className="search-result-aside-content">
        <div className="search-result-aside-content-item-primary">
          <PlusOutlined />
          <span>새 질문</span>
        </div>
        <div className="search-result-aside-content-item">
          <span className="search-result-aside-content-title">최근 검색</span>
        </div>
      </div>
    </div>
  );
};

export default SearchResultAside;
