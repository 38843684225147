import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Statistic,
  List,
  Progress,
  Button,
  Tabs,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { serviceApi } from "../../config/serviceApi";
import { pages } from "../../constants/PagePaths";
import { MyProjectModel } from "../../models/service/ServiceInfo";
import styles from "./Dashboard.module.scss";
import { getServiceStatus } from "../service/ServiceUtil";
import { ExpertStatModel } from "../../models/service/ExpertModel";
import { ServiceRequestModel } from "../../models/service/ServiceRequestModel";
import { ProjectDetailModel } from "../../models/service/ProjectModel";

// 전문가용 더미 데이터
const expertDummyData: ExpertStatModel = {
  totalProjects: 10,
  completedProjects: 5,
  ongoingProjects: 3,
  totalRevenue: 50000000,
  pendingApprovals: 2,
};

// 전문가 대시보드 렌더링 함수
export const ExpertDashboard: React.FC = () => {
  const [expertData, setExpertData] = useState(expertDummyData);
  const [projects, setProjects] = useState<ProjectDetailModel[]>([]);
  const [serviceRequests, setServiceRequests] = useState<ServiceRequestModel[]>(
    []
  );

  const { TabPane } = Tabs;

  useEffect(() => {
    async function fetch() {
      const myProjectRes = await serviceApi.getMyProjectsByExpert();

      if (myProjectRes.status == 200) {
        setProjects(myProjectRes.data);
      }

      const serviceRequestsRes = await serviceApi.getServiceRequests();
      if (serviceRequestsRes.status == 200) {
        setServiceRequests(serviceRequestsRes.data);
      }
      // const expertStatRes = await serviceApi.getMyExpertStat();
      // if (expertStatRes.status == 200) {
      //   setExpertData(expertStatRes.data);
      // }
    }
    fetch();
  }, []);

  return (
    <>
      <Row gutter={16} style={{ justifyContent: "start" }}>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="총 프로젝트"
              value={projects.length}
              prefix={<ProjectOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="완료된 프로젝트"
              value={
                projects.filter((project) => project.status == "completed")
                  .length
              }
              prefix={<CheckCircleOutlined />}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="진행 중인 프로젝트"
              value={
                projects.filter((project) => project.status != "completed")
                  .length
              }
              prefix={<ClockCircleOutlined />}
            />
          </Card>
        </Col>
        {/* <Col xs={24} sm={12} md={6}>
          <Card>
            <Statistic
              title="총 수익"
              value={expertData.totalRevenue}
              prefix={<DollarOutlined />}
              precision={0}
              formatter={(value) =>
                `₩ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              }
            />
          </Card>
        </Col> */}
      </Row>
      <Card
        className={styles.projectList}
        title="프로젝트 및 서비스 요청"
        style={{ marginTop: "20px" }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="진행 중인 프로젝트" key="1">
            <List
              itemLayout="horizontal"
              dataSource={projects}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Link to={`${pages.project}/${item.id}`}>
                      <Button type="primary">상세 보기</Button>
                    </Link>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Link to={`${pages.project}/${item.id}`}>
                        {item.service_request.service.title}
                      </Link>
                    }
                    description={
                      <>
                        <Tag
                          color={
                            item.status === "ongoing" ? "processing" : "success"
                          }
                        >
                          {item.status === "ongoing"
                            ? "진행 중"
                            : item.status === "completed"
                            ? "완료"
                            : "대기"}
                        </Tag>
                        {/* {item.pendingApprovals > 0 && (
                          <Tag color="warning">
                            승인 대기 {item.pendingApprovals}건
                          </Tag>
                        )} */}
                        <div style={{ marginTop: "10px" }}>
                          진행률: {item.progress}%
                          <Progress
                            percent={item.progress}
                            status="active"
                            style={{
                              width: "200px",
                              marginBottom: 0,
                              marginTop: "5px",
                            }}
                          />
                        </div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </TabPane>
          <TabPane tab="새로운 서비스 요청" key="2">
            <List
              itemLayout="horizontal"
              dataSource={serviceRequests}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    item.status == "expert_selected" ? (
                      <Button disabled>매칭 완료</Button>
                    ) : (
                      <Link to={`${pages.expertMatching}/${item.id}`}>
                        <Button type="primary">매칭 참여</Button>
                      </Link>
                    ),
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Link to={`${pages.expertMatching}/${item.id}`}>
                        {item.service.title}
                      </Link>
                    }
                    description={
                      <>
                        <Tag
                          color={
                            item.status === "expert_selected"
                              ? "success"
                              : "blue"
                          }
                        >
                          {item.status === "expert_selected"
                            ? "매칭 완료"
                            : getServiceStatus(item.status).title}
                        </Tag>
                        <span>의뢰인: {item.client.username}</span>
                        <span style={{ marginLeft: "10px" }}>
                          신청일:{" "}
                          {
                            new Date(item.created_at)
                              .toISOString()
                              .split("T")[0]
                          }
                        </span>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};
