import {
  ClockCircleOutlined,
  PhoneOutlined,
  ProjectOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";

export function getServiceStatus(status: string) {
  return steps.filter((step) => step.status === status)[0];
}

export const steps = [
  {
    index: 0,
    status: "pending",
    title: "신청 접수",
    description: "서비스 신청이 접수되었습니다.",
    icon: <ClockCircleOutlined />,
  },
  {
    index: 1,
    status: "matching",
    title: "전문가 매칭 중",
    description: "전문가들이 프로젝트에 지원하고 있습니다.",
    icon: <SyncOutlined spin />,
  },
  {
    index: 2,
    status: "expert_selected",
    title: "전문가 선택",
    description: "원하는 전문가를 선택해주세요.",
    icon: <UserOutlined />,
  },
  {
    index: 3,
    status: "consulting",
    title: "전화 상담",
    description: "전문가와 전화 상담을 진행합니다.",
    icon: <PhoneOutlined />,
  },
  {
    index: 4,
    status: "in_progress",
    title: "프로젝트 시작",
    description: "전문가와 프로젝트를 시작합니다.",
    icon: <ProjectOutlined />,
  },
];
