import React, { useCallback, useEffect, useRef } from "react";
import { InboxOutlined } from "@ant-design/icons";
import "./AiSearchInput.scss";

interface AiSearchInputProps {
  onSearchSubmit: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent,
    result: string
  ) => void;
  tooltipText: string;
  placeholder?: string;
}

const AiSearchInput: React.FC<AiSearchInputProps> = ({
  onSearchSubmit,
  placeholder,
  tooltipText,
}) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleSubmit = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent
    ) => {
      try {
        const textareaElement = document.getElementById(
          "autoResizeTextarea"
        ) as HTMLTextAreaElement;
        if (textareaElement && textareaElement.value.trim() !== "") {
          onSearchSubmit(e, textareaElement.value);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [onSearchSubmit]
  );

  useEffect(() => {
    const textarea = document.getElementById("autoResizeTextarea");

    let isComposing = false;

    const tooltipShow = () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.visibility = "visible";
        tooltipRef.current.style.opacity = "1";
      }
    };
    const tooltipHide = () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.visibility = "hidden";
        tooltipRef.current.style.opacity = "0";
      }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isComposing) return;
      const target = e.target as HTMLTextAreaElement;
      if (target.value.trim() === "") {
        target.value = "";
        return;
      }
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
        return;
      } else {
        if (target.scrollHeight > 280) {
          target.style.overflow = "auto";
          if (target.value === "") {
            target.style.height = "0";
          }
        } else {
          target.style.height = "auto";
          target.style.height = target.scrollHeight + "px";
        }
      }
    };

    const handleCompositionStart = () => {
      isComposing = true;
    };

    const handleCompositionEnd = () => {
      isComposing = false;
    };

    tooltipShow();
    setTimeout(() => {
      tooltipHide();
    }, 3000);

    if (textarea) {
      textarea.addEventListener("keydown", handleKeyDown);
      textarea.addEventListener("compositionstart", handleCompositionStart);
      textarea.addEventListener("compositionend", handleCompositionEnd);
    }
    return () => {
      if (textarea) {
        textarea.removeEventListener("keydown", handleKeyDown);
        textarea.removeEventListener(
          "compositionstart",
          handleCompositionStart
        );
        textarea.removeEventListener("compositionend", handleCompositionEnd);
      }
    };
  }, [handleSubmit]);

  return (
    <div className="search-input-wrapper">
      <textarea
        id="autoResizeTextarea"
        placeholder={placeholder || "무엇이든 물어보세요."}
        rows={1}
      />
      <div className="search-input-footer">
        <div className="search-input-footer-left">
          <button type="button" className="btn btn-secondary">
            파일첨부
            <InboxOutlined />
          </button>
        </div>
        <div className="search-input-footer-right">
          <button
            type="button"
            className="btn btn-primary tooltip-container"
            disabled
          >
            PRO
            {tooltipText && (
              <div ref={tooltipRef} className="tooltip-text">
                {tooltipText}
              </div>
            )}
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            검색
          </button>
        </div>
      </div>
    </div>
  );
};

export default AiSearchInput;
