import React, { useEffect, useRef } from "react";
import { marked } from "marked";
import Typed from "typed.js";

interface MarkdownTyperProps {
  markdown: string;
}

const MarkdownTyper: React.FC<MarkdownTyperProps> = ({ markdown }) => {
  const el = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!el.current) return;
    // Markdown을 HTML로 변환
    const htmlContent = marked.parse(markdown);

    // Typed.js 설정
    const typed = new Typed(el.current, {
      strings: [htmlContent as string],
      typeSpeed: 10,
      showCursor: false,
      smartBackspace: true,
      contentType: "html", // HTML을 인식하도록 설정
    });

    // 컴포넌트 언마운트 시 Typed.js 인스턴스 제거
    return () => {
      typed.destroy();
    };
  }, [markdown]);

  return <div ref={el} />;
};

export default MarkdownTyper;
