import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  Table,
  Tabs,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Radio,
  Select,
  InputNumber,
  DatePicker,
  Spin,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import styles from "./ServiceDetailPage.module.scss";
import "antd/dist/antd.css"; // Ant Design의 CSS 파일
import { serviceApi } from "../../config/serviceApi";
import { api } from "../../config/api";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";
import { ExternalProjectList } from "../../components/ExternalProjectList";
import { ServiceModel } from "../../models/service/ServiceModel";
import { AxiosError } from "axios";
import AlertContext from "../../contexts/AlertContext";
import { Cookies } from "../../constants/Cookies";
import { util } from "../../config/util";
import { pages } from "../../constants/PagePaths";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

interface ServiceDetailPageProps {}

const ServiceDetailPage: React.FC<ServiceDetailPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState("1");
  const [serviceData, setServiceData] = useState<ServiceModel | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [attachments, setAttachments] = useState<string[]>([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const alertContext = useContext(AlertContext);

  useEffect(() => {
    async function fetch() {
      const fetchService = async () => {
        try {
          const response = await serviceApi.getServiceCategory(+id);
          setServiceData(response.data);
        } catch (error) {
          message.error('서비스 데이터를 불러오는데 실패했습니다.');
        }
      };

      const fetchMyInfo = async () => {
        try {
          const response = await api.getMyInfo();
          const { phone, email } = response.data;
          form.setFieldsValue({ phone, email });
        } catch (error) {
          message.error('사용자 정보를 불러오는데 실패했습니다.');
        }
      };

      // 두 요청을 독립적으로 실행
      fetchService();
      fetchMyInfo();
    }
    fetch();
  }, [id]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    const res = await serviceApi.postServiceRequest({
      // attachments: attachments,
      title: values.name,
      service: +id,
      company_name: values.company,
      contact: values.phone,
      email: values.email,
      budget: values.projectBudget,
      desired_completion_date:
        values.expectedCompletionDate.format("YYYY-MM-DD"),
      description: values.description,
      selected_option: values.serviceOption,
      current_cost: values.projectBudget,
      file_urls: attachments,
    });

    if (res.status == 201) {
      message.success("용역 신청이 완료되었습니다.");
      setIsModalVisible(false);
      form.resetFields();
      // 대시보드로 이동
      history.push("/dashboard");
    } else {
      setIsModalVisible(false);
      message.error(res.data);
    }
  };

  if (!serviceData) {
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" />
      </div>
    );
  }

  const handleChangeFiles = async (e: UploadChangeParam<UploadFile<any>>) => {
    const megaByte = 1000 * 1000;
    const fileSizeLimit = 20; //10MB
    const fileCountLimit = 5;
    if (e.fileList) {
      if (e.fileList?.length === 0) return;
      const files = [];

      let lengthSum = 0;
      for (let i = 0; i < (e.fileList.length || 0); i++) {
        let file = e.fileList[i];
        lengthSum += file.size!;
        if (lengthSum < fileSizeLimit * megaByte && i < fileCountLimit) {
          const res = await api.uploadFileAsync(file.originFileObj!);
          files.push(res.data.url);
        } else {
          message.error(
            `파일은 최대 ${fileCountLimit}개, 합계 ${fileSizeLimit}MB를 넘을 수 없습니다.`
          );
          break;
        }
      }
      setAttachments(files);
      const newFileList = e.fileList.map((file) => {
        if (file.uid === e.file.uid) {
          file.status = "done"; // 원하는 상태로 변경
        }
        return file;
      });
      setFileList(newFileList);
    }
  };

  const detailColumns = [
    { title: "항목", dataIndex: "item", key: "item" },
    {
      title: "옵션 A",
      dataIndex: "option_a",
      key: "option_a",
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseOutlined style={{ color: "#f5222d" }} />
        ),
    },
    {
      title: "옵션 B",
      dataIndex: "option_b",
      key: "option_b",
      render: (value: boolean) =>
        value ? (
          <CheckOutlined style={{ color: "#52c41a" }} />
        ) : (
          <CloseOutlined style={{ color: "#f5222d" }} />
        ),
    },
  ];

  const priceColumns = [
    { title: "총 공사비", dataIndex: "range", key: "range" },
    { title: "옵션 A", dataIndex: "option_a", key: "option_a" },
    { title: "옵션 B", dataIndex: "option_b", key: "option_b" },
  ];

  const isUserLoggedIn = () => {
    return !!util.getCookie(Cookies.authKey);
  };

  const handleServiceRequest = () => {
    if (!isUserLoggedIn()) {
      alertContext.showAlert("서비스 신청을 위해 로그인이 필요합니다.", () => {
        history.push(pages.login);
      });
    } else {
      showModal();
    }
  };

  return (
    <div className={styles.container}>
      <Title level={2}>{serviceData.title}</Title>
      <Paragraph className={styles.description}>
        {serviceData.description}
      </Paragraph>

      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        className={styles.tabs}
      >
        <TabPane tab="세부사항" key="1">
          <Table
            columns={detailColumns}
            dataSource={serviceData.details}
            pagination={false}
          />
        </TabPane>
        <TabPane tab="가격" key="2">
          <Table
            columns={priceColumns}
            dataSource={serviceData.prices}
            pagination={false}
          />
        </TabPane>
      </Tabs>

      <div className={styles.buttonContainer}>
        <Button type="primary" size="large" onClick={handleServiceRequest}>
          서비스 신청
        </Button>
      </div>

      <ExternalProjectList />

      <Modal
        title="용역 신청"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          name="service_request"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="이름"
            rules={[{ required: true, message: "이름을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company"
            label="회사명"
            rules={[{ required: true, message: "회사명을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="연락처"
            rules={[{ required: true, message: "연락처를 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="이메일"
            rules={[
              {
                required: true,
                type: "email",
                message: "올바른 이메일을 입력해주세요",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="serviceOption"
            label="옵션 선택"
            rules={[{ required: true, message: "옵션을 선택해주세요" }]}
          >
            <Radio.Group>
              <Radio value="A">옵션 A</Radio>
              <Radio value="B">옵션 B</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="projectBudget"
            label="프로젝트 예산"
            rules={[{ required: true, message: "프로젝트 산을 입력해주세요" }]}
          >
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="expectedCompletionDate"
            label="완료 희망일"
            rules={[
              { required: true, message: "완료 희망일을 입력해주세요" },

              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const today = dayjs().startOf("day");
                  const inputDate = dayjs(value, "YYYY-MM-DD", true);

                  if (inputDate.isValid() && inputDate.isAfter(today)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("현재 날짜 이후로 입력해주세요.")
                  );
                },
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
              disabledDate={(current) =>
                !!current && current < dayjs().startOf("day")
              }
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="프로젝트 설명"
            rules={[
              { required: true, message: "프로젝트 설명을 입력해주세요" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="attachments"
            label="첨부 파일"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
          >
            <Upload
              fileList={fileList}
              action={""}
              name="file"
              listType="text"
              customRequest={() => {}}
              onChange={(e) => {
                if (e.file.status === "uploading") {
                  handleChangeFiles(e);
                }

                //
              }}
            >
              <Button icon={<UploadOutlined />}>파일 선택</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              신청하기
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ServiceDetailPage;
