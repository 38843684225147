import { LinkOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { aiApi } from "../../config/aiApi";

interface SearchResultCitationsProps {
  citations: string[];
}

const SearchResultCitations: React.FC<SearchResultCitationsProps> = ({
  citations,
}) => {
  const [previews, setPreviews] = useState<
    (
      | { title: string; description: string; url: string; img?: string }
      | undefined
    )[]
  >([]);

  useEffect(() => {
    const loadPreviews = async () => {
      const previewsData = await Promise.all(
        citations.map((citation) => aiApi.getLinkPreview(citation))
      );
      const formattedPreviews = previewsData.map((response, index) => {
        try {
          const url =
            response.tags.og_url || response.tags.url || citations[index];
          return {
            title: response.tags.og_title || response.tags.title,
            description:
              response.tags.og_description || response.tags.description,
            url,
            img: response.tags.og_image || response.tags.image,
          };
        } catch (error) {
          console.error(error);
          return undefined;
        }
      });

      // 중복 URL 제거
      const uniquePreviews = Array.from(
        new Map(
          formattedPreviews
            .filter((item) => item !== undefined)
            .map((item) => [item.url, item])
        ).values()
      );

      setPreviews(uniquePreviews);
    };

    loadPreviews();
  }, [citations]);

  return (
    <>
      <span className="search-result-content-body-citations-item-title">
        참고 자료
      </span>
      <div className="search-result-content-body-citations-item-list">
        {previews.map(
          (preview, index) =>
            preview && (
              <div
                key={index}
                className="search-result-content-body-citations-item-list-item"
              >
                {preview?.img && (
                  <div className="preview-img-container">
                    <img src={preview?.img} alt={preview?.title} />
                  </div>
                )}
                <div className="preview-title-row">
                  <span className="preview-index">{index + 1}</span>
                  <span className="preview-title">{preview?.title}</span>
                </div>
                <p className="preview-description">{preview?.description}</p>
                <span className="preview-url">
                  <LinkOutlined />
                  <a
                    href={preview?.url}
                    target="_blank"
                    className="preview-url-text"
                    rel="noreferrer"
                  >
                    {preview?.url}
                  </a>
                </span>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default SearchResultCitations;
