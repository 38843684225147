import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  Typography,
  Descriptions,
  Spin,
  Progress,
  Tabs,
  List,
  Comment,
  Avatar,
  InputNumber,
  Button,
  message,
  Tag,
  Modal,
  Space,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Upload,
  Rate,
} from "antd";
import {
  UserOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  UploadOutlined,
  FileOutlined,
  DownloadOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import moment from "moment";
import styles from "./ProjectDetailPage.module.scss";
import { serviceApi } from "../../config/serviceApi";
import { api } from "../../config/api";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";

import { TaskModel } from "../../models/service/TaskModel";
import { ProjectDetailModel } from "../../models/service/ProjectModel";
import { useRef } from "react";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { UserInfoModel } from "../../models/user/UserInfo";
import { FileModel } from "../../models/service/ServiceRequestModel";
import { DeleteOutline } from "@material-ui/icons";

const { Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ProjectDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<ProjectDetailModel | null>(null);
  const [editingProgress, setEditingProgress] = useState(false);
  const [newProgress, setNewProgress] = useState(0);
  const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const [userRole, setUserRole] = useState<"expert" | "client" | null>(null);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingServiceCost, setEditingServiceCost] = useState(false);
  const [newServiceCost, setNewServiceCost] = useState(0);
  const [isMessagingVisible, setIsMessagingVisible] = useState(false);
  const location = useLocation();
  const [isEditTaskModalVisible, setIsEditTaskModalVisible] = useState(false);
  const [editingTask, setEditingTask] = useState<TaskModel | null>(null);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [attachments, setAttachments] = useState<string[]>([]);

  const [comment, setComment] = useState<string>("");
  const [myInfo, setMyInfo] = useState<UserInfoModel>();

  const [allFiles, setAllFiles] = useState<FileModel[]>([]);

  const registerComment = async () => {
    if (project!.has_review) {
      message.error("이미 종료된 프로젝트입니다.");
      return;
    }
    if (selectedTask && comment) {
      const res = await serviceApi.postComment(+id, selectedTask?.id, comment);
      if (res.status == 201) {
        message.success("댓글이 등록되었습니다.");
        setSelectedTask((prevSelcetedTask) => ({
          ...prevSelcetedTask!,
          comments: [...prevSelcetedTask!.comments, res.data],
        }));
        refreshProjects();
        setComment("");
      }
    }
  };

  const refreshProjects = async () => {
    const projectRes = await serviceApi.getProject(+id);
    setProject(projectRes.data);
  };

  useEffect(() => {
    async function fetch() {
      const projectRes = await serviceApi.getProject(+id);
      setProject(projectRes.data);
      const checkRes = await serviceApi.checkExpert();
      const isExpertView = checkRes.data.is_expert;
      setUserRole(isExpertView ? "expert" : "client");
      const res = await api.getMyInfo();
      setMyInfo(res.data);
      if (
        projectRes.data.client.email !== res.data.email &&
        projectRes.data.expert.user.email !== res.data.email
      ) {
        setProject(null);
      }
      
      const serviceFiles = projectRes.data.service_request.files || [];
      const taskFiles = projectRes.data.tasks?.map((task) => task.files || []).flat() || [];
      setAllFiles([...serviceFiles, ...taskFiles]);
    }
    fetch();
  }, [id]);

  const handleProgressEdit = () => {
    setEditingProgress(true);
    setNewProgress(project?.progress || 0);
  };

  const handleProgressCancel = () => {
    setEditingProgress(false);
    setNewProgress(project?.progress || 0);
  };

  const handleProgressSave = async () => {
    if (project) {
      if (project!.has_review) {
        message.error("이미 종료된 프로젝트입니다.");
        return;
      }
      const res = await serviceApi.updateProject(project.id, newProgress);
      if (res.status == 200) {
        message.success("진행률이 업데이트되었습니다.");
        setEditingProgress(false);
        setProject((prevProject) => ({
          ...prevProject!,
          progress: newProgress,
        }));
      }
      refreshProjects(); // 프로젝트 데이터 즉시 새로고���
    }
  };

  const handleAddTask = async (values: any) => {
    if (project) {
      if (project!.has_review) {
        message.error("이미 종료된 프로젝트입니다.");
        return;
      }
      const res = await serviceApi.postProjectTask(project?.id, {
        title: values.title,
        description: values.description,
        deadline: new Date(values.dueDate).format("yyyy-MM-dd"),
        assignee_id: project.expert.id,
        file_urls: attachments,
      });
      if (res.status == 201) {
        message.success("새 작업이 추가되었습니다.");
        setIsAddTaskModalVisible(false);
        form.resetFields();
        setAttachments([]);
        refreshProjects();
      } else {
        message.error(res.data.detail);
      }
    }
  };

  const handleChangeFiles = async (e: UploadChangeParam<UploadFile<any>>) => {
    const megaByte = 1000 * 1000;
    const fileSizeLimit = 20; //10MB
    const fileCountLimit = 5;
    if (e.fileList) {
      if (e.fileList?.length === 0) return;
      const files = [];
      const filenames: string[] = [];
      let lengthSum = 0;
      for (let i = 0; i < (e.fileList.length || 0); i++) {
        let file = e.fileList[i];
        lengthSum += file.size!;
        if (lengthSum < fileSizeLimit * megaByte && i < fileCountLimit) {
          const res = await api.uploadFileAsync(file.originFileObj!);
          files.push(res.data.url);
          filenames.push(file.name);
        } else {
          message.error(
            `파일은 최대 ${fileCountLimit}개, 합계 ${fileSizeLimit}MB를 넘을 수 없습니다.`
          );
          break;
        }
      }
      setAttachments(files);
      const newFileList = e.fileList.map((file) => {
        if (file.uid === e.file.uid) {
          file.status = "done"; // 원하는 상태로 변경
        }
        return file;
      });
      setFileList(newFileList);
    }
  };

  const handleApprove = async (taskId: number) => {
    if (project) {
      const res = await serviceApi.proceedProjectTask(+id, taskId);
      if (res.status == 200) {
        message.success("작업이 승인되었습니다.");
        refreshProjects();
      }
    }
  };

  const handleRejectApproval = async (taskId: number) => {
    if (project) {
      const res = await serviceApi.cancelProjectTask(+id, taskId);
      if (res.status == 200) {
        message.success("작업이 반려되었습니다.");
        refreshProjects();
      }
    }
  };

  const getStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <Tag
            style={{ display: "flex", alignItems: "center" }}
            icon={<ClockCircleOutlined />}
            color="default"
          >
            대기 중
          </Tag>
        );
      case "rejected":
        return (
          <Tag
            style={{ display: "flex", alignItems: "center" }}
            icon={<ClockCircleOutlined />}
            color="warning"
          >
            반려
          </Tag>
        );
      case "approved":
        return (
          <Tag
            style={{ display: "flex", alignItems: "center" }}
            icon={<CheckCircleOutlined />}
            color="success"
          >
            승인 완료
          </Tag>
        );
      default:
        return null;
    }
  };

  const showTaskDetails = (task: TaskModel) => {
    setSelectedTask(task);
    setIsModalVisible(true);
  };

  const showAddTaskModal = () => {
    setIsAddTaskModalVisible(true);
  };

  const handleServiceCostEdit = () => {
    setEditingServiceCost(true);
    setNewServiceCost(project?.service_cost || 0);
  };

  const handleServiceCostSave = async () => {
    if (project) {
      const res = await serviceApi.updateProject(
        project.id,
        undefined,
        newServiceCost
      );
      if (res.status == 200) {
        message.success("용역 비용이 업데이트되었습니���.");
        setEditingServiceCost(false);
        const updatedProject = { ...project, service_cost: newServiceCost };
        setProject(updatedProject);
      }
    }
  };

  const handleServiceCostCancel = () => {
    setEditingServiceCost(false);
  };

  const renderProgressSection = () => {
    if (editingProgress) {
      return (
        <>
          <InputNumber
            min={0}
            max={100}
            value={newProgress}
            onChange={(value) => setNewProgress(value || 0)}
          />
          <Button
            onClick={handleProgressSave}
            type="primary"
            style={{ marginLeft: "10px" }}
          >
            저장
          </Button>
          <Button onClick={handleProgressCancel} style={{ marginLeft: "10px" }}>
            취소
          </Button>
        </>
      );
    }
    return (
      <>
        <Progress percent={project?.progress} />
        {project?.expert.user.email === myInfo?.email && (
          <Button
            onClick={handleProgressEdit}
            type="link"
            icon={<EditOutlined />}
          />
        )}
      </>
    );
  };

  const renderServiceCostSection = () => {
    if (editingServiceCost) {
      return (
        <>
          <InputNumber
            value={newServiceCost}
            onChange={(value) => setNewServiceCost(value || 0)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) =>
              value ? parseFloat(value.replace(/\$\s?|(,*)/g, "")) : 0
            }
          />
          <Button
            onClick={handleServiceCostSave}
            type="primary"
            style={{ marginLeft: "10px" }}
          >
            저장
          </Button>
          <Button
            onClick={handleServiceCostCancel}
            style={{ marginLeft: "10px" }}
          >
            취소
          </Button>
        </>
      );
    }
    return (
      <>
        {project?.service_cost !== undefined
          ? `${project.service_cost.toLocaleString()} 원`
          : "N/A"}
        {project?.expert.user.email === myInfo?.email && (
          <Button
            onClick={handleServiceCostEdit}
            type="link"
            icon={<EditOutlined />}
          />
        )}
      </>
    );
  };

  const showEditTaskModal = (task: TaskModel) => {
    setEditingTask(task);
    setIsEditTaskModalVisible(true);
  };

  const handleEditTask = async (values: any) => {
    if (project && editingTask) {
      if (project!.has_review) {
        message.error("이미 종료�� 프로젝트입니다.");
        return;
      }
      const res = await serviceApi.updateTask(
        +id,
        editingTask.id,
        values.title,
        values.description,
        values.dueDate.format("YYYY-MM-DD"),
        attachments.length > 0 ? attachments : undefined
      );
      if (res.status == 200) {
        setEditingTask(null);
        setIsEditTaskModalVisible(false);
        message.success("작업이 수정되었습니다.");
        form.resetFields();
        setAttachments([]);
        refreshProjects();
      } else {
        message.error(res.data.detail);
      }
    }
  };

  const registerReview = async (values: any) => {
    if (project) {
      const res = await serviceApi.postProjectReview(+id, {
        content: values.description,
        rating: values.rating,
      });
      if (res.status == 201) {
        message.success("리뷰가 등록되었습니다.");
        refreshProjects();
        setIsMessagingVisible(false);
      }
    }
  };

  const deleteTask = async (task: TaskModel) => {
    if (project!.has_review) {
      message.error("이미 종료된 프로젝트입니다.");
      return;
    }
    const res = await serviceApi.deleteProjectTask(+id, task.id);
    if (res.status == 204) {
      message.success("태스크가 삭제되었습니다.");
      // window.location.reload();
    }
  };

  const deleteComment = async (taskId: number, commentId: number) => {
    if (project!.has_review) {
      message.error("이미 종료된 프로젝트입니다.");
      return;
    }
    const res = await serviceApi.deleteComment(+id, taskId, commentId);
    if (res.status == 204) {
      message.success("댓글이 삭제되었습니다.");
      setSelectedTask((prevSelcetedTask) => ({
        ...prevSelcetedTask!,
        comments: prevSelcetedTask!.comments.filter(
          (comment) => comment.id != commentId
        ),
      }));
      refreshProjects();
    }
  };

  if (!project) {
    return (
      <div className={styles.loadingContainer} style={{ minHeight: "calc(100vh - 200px)" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={() => history.goBack()}
        style={{ marginBottom: "20px", display: 'flex', alignItems: 'center' }}
      >
        목록으로 돌아가기
      </Button>

      <Card>
        <div className={styles.projectHeader}>
          <Title level={2}>{project?.service_request.title}</Title>
          <Space>
            <Button
              icon={<SyncOutlined />}
              size="large"
              onClick={refreshProjects}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            />
            {project?.progress === 100 &&
            project.client.email === myInfo?.email &&
            !project.has_review ? (
              <Button
                type="primary"
                icon={<MessageOutlined style={{ verticalAlign: 'middle' }} />}
                size="large"
                className={styles.contactButton}
                onClick={() => setIsMessagingVisible(true)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                리뷰 쓰기
              </Button>
            ) : null}
            {project.has_review ? (
              <Button
                type="primary"
                icon={<MessageOutlined style={{ verticalAlign: 'middle' }} />}
                size="large"
                className={styles.contactButton}
                disabled={true}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                리뷰 쓰기 완료
              </Button>
            ) : null}
          </Space>
        </div>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="상태">
            {project?.status == "completed" ? "완료" : "진행 중"}
          </Descriptions.Item>
          <Descriptions.Item label="진행률">
            {renderProgressSection()}
          </Descriptions.Item>
          <Descriptions.Item label="시작">
            {new Date(project?.created_at).format("yyyy-MM-dd")}
          </Descriptions.Item>
          <Descriptions.Item label="종료">
            {project.service_request.desired_completion_date}
          </Descriptions.Item>
          <Descriptions.Item label="예산">
            {project?.service_request.budget !== undefined
              ? `${project.service_request.budget.toLocaleString()} 원`
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="용역 비용">
            {renderServiceCostSection()}
          </Descriptions.Item>
          <Descriptions.Item label="고객사">
            {project?.client.username}
          </Descriptions.Item>
          <Descriptions.Item label="담당자">
            {project?.expert.nickname}
          </Descriptions.Item>
          <Descriptions.Item label="설명" span={2}>
            {project?.description}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
        <TabPane tab="작업 목록" key="1">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showAddTaskModal}
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            새 작업 추가
          </Button>
          <List
            itemLayout="horizontal"
            dataSource={project?.tasks || []}
            renderItem={(item: TaskModel) => (
              <List.Item>
                <Row style={{ width: "100%" }}>
                  <Col span={16}>
                    <List.Item.Meta
                      title={<strong>{item.title}</strong>}
                      description={
                        <Space direction="vertical" size="small">
                          <Space align="center">
                            {getStatusTag(item.status)}
                            <span>담당자: {item.assignee.username}</span>
                          </Space>
                          <span>마감일: {item.deadline}</span>
                        </Space>
                      }
                    />
                  </Col>
                  <Col
                    span={8}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => showTaskDetails(item)}
                      icon={<EditOutlined />}
                      style={{
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      상세보기
                    </Button>
                    {item.creator?.email === myInfo?.email ? (
                      <Button
                        onClick={() => deleteTask(item)}
                        icon={<DeleteOutline />}
                        style={{ marginRight: "10px" }}
                      >
                        삭제
                      </Button>
                    ) : (
                      ""
                    )}
                    {/* */}
                    {item.creator?.email === myInfo?.email && (
                      <Button
                        onClick={() => showEditTaskModal(item)}
                        type="primary"
                        icon={<EditOutlined />}
                        style={{ marginRight: "10px" }}
                      >
                        수정
                      </Button>
                    )}
                    {item.status === "pending" &&
                    item.assignee.email === myInfo?.email ? (
                      <Button
                        onClick={() => handleApprove(item.id)}
                        type="primary"
                        style={{ marginRight: "10px" }}
                      >
                        승인
                      </Button>
                    ) : (
                      ""
                    )}
                    {/* {item.status === "approved" &&
                    item.assignee.email === myInfo?.email ? (
                      <Button
                        key={"cancel"}
                        onClick={() => handleCancelApproval(item.id)}
                        type="default"
                      >
                        승인취소
                      </Button>
                    ) : (
                      ""
                    )} */}
                    {item.status === "pending" &&
                    item.assignee.email === myInfo?.email ? (
                      <Button
                        key={"cancel"}
                        onClick={() => handleRejectApproval(item.id)}
                        type="default"
                      >
                        반려
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="첨부파일" key="2">
          <List
            itemLayout="horizontal"
            dataSource={allFiles} //파일
            renderItem={(item: FileModel) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() => window.open(item.file_url, "_blank")}
                  >
                    다운로드
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<FileOutlined style={{ fontSize: "24px" }} />}
                  title={decodeURIComponent(item.file_name)}
                  description={
                    <Space>
                      {/* <span>크기: {formatFileSize(item.file_size)}</span> */}
                      <span>
                        업로드 날짜:{" "}
                        {new Date(item.uploaded_at).format("yyyy-MM-dd")}
                      </span>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>

      <Modal
        title="작업 상세 정보"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            닫기
          </Button>,
          selectedTask?.status === "pending" &&
          selectedTask.assignee.email === myInfo?.email ? (
            <Button
              key="approve"
              type="primary"
              onClick={() => handleApprove(selectedTask.id)}
            >
              승인
            </Button>
          ) : (
            ""
          ),
          // selectedTask?.status === "approved" ? (
          //   <Button
          //     key="cancel"
          //     type="default"
          //     onClick={() => handleCancelApproval(selectedTask.id)}
          //   >
          //     승인 취소
          //   </Button>
          // ) : (
          //   ""
          // ),
          selectedTask?.status === "pending" &&
          selectedTask.assignee.email === myInfo?.email ? (
            <Button
              key="cancel"
              type="default"
              onClick={() => handleRejectApproval(selectedTask.id)}
            >
              반려
            </Button>
          ) : (
            ""
          ),
        ]}
      >
        {selectedTask && (
          <>
            <h3>{selectedTask.title}</h3>
            <p>{selectedTask.description}</p>
            {/* <p>상태: {getStatusTag(selectedTask.status)}</p> */}
            <p>담당자: {selectedTask.assignee.username}</p>
            <p>마감일: {selectedTask.deadline}</p>
            <div>
              첨부파일 :
              {selectedTask.files.map((file) => {
                return (
                  <a key={file.file_name} href={file.file_url} target="_blank">
                    {file.file_name}
                  </a>
                );
              })}
            </div>
            <h4>댓글</h4>
            <List
              style={{ height: 200, overflowY: "scroll" }}
              dataSource={selectedTask.comments}
              renderItem={(comment) => (
                <Comment
                  author={comment.author.username}
                  datetime={comment.date}
                  actions={
                    comment.author.email === myInfo?.email
                      ? [
                          <Space>
                            {/* <Button type="link" onClick={() => {}}>
                              수정
                            </Button> */}
                            <Button
                              type="link"
                              danger
                              onClick={() => {
                                deleteComment(selectedTask.id, comment.id);
                              }}
                            >
                              삭제
                            </Button>
                          </Space>,
                        ]
                      : []
                  }
                  content={
                    false ? (
                      <Space>
                        <Input.TextArea
                          value={""}
                          onChange={(e) => {}}
                          autoSize
                        />
                        <Button type="primary" size="small" onClick={() => {}}>
                          저장
                        </Button>
                        <Button size="small" onClick={() => {}}>
                          취소
                        </Button>
                      </Space>
                    ) : (
                      comment.content
                    )
                  }
                />
              )}
            />
            <div style={{ marginTop: "20px" }}>
              <Form.Item>
                <Input.TextArea
                  placeholder="댓글을 입��하세요"
                  autoSize={{ minRows: 2 }}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </Form.Item>
              <Button
                type="primary"
                onClick={async () => {
                  await registerComment();
                }}
                disabled={false}
              >
                댓글 작성
              </Button>
            </div>
          </>
        )}
      </Modal>

      <Modal
        title="새 ���업 추가"
        visible={isAddTaskModalVisible}
        onCancel={() => setIsAddTaskModalVisible(false)}
        footer={null}
        getContainer={false}
        zIndex={1050} // zIndex 추가
      >
        <Form form={form} onFinish={handleAddTask} layout="vertical">
          <Form.Item
            name="title"
            label="작업 제목"
            rules={[{ required: true, message: "작업 제목을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="작업 설명"
            rules={[{ required: true, message: "작업 설명을 입력해주세요" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="dueDate"
            label="마감일"
            rules={[
              { required: true, message: "마감일을 선택해주세요" },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const today = dayjs().startOf("day");
                  const inputDate = dayjs(value, "YYYY-MM-DD", true);

                  if (inputDate.isValid() && inputDate.isAfter(today)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("현재 날짜 이후로 입력해주세요.")
                  );
                },
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
              disabledDate={(current) =>
                !!current && current < dayjs().startOf("day")
              }
            />
          </Form.Item>
          <Form.Item
            name="assignedTo"
            label="담당자"
            initialValue={project.expert.nickname}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            name="attachments"
            label="첨부파일"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
          >
            <Upload
              fileList={fileList}
              action={""}
              name="file"
              listType="text"
              customRequest={() => {}}
              onChange={(e) => {
                if (e.file.status === "uploading") {
                  handleChangeFiles(e);
                }
              }}
            >
              <Button icon={<UploadOutlined />}>파일 선택</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              작업 추가
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="리뷰 쓰기"
        visible={isMessagingVisible}
        onCancel={() => setIsMessagingVisible(false)}
        footer={null}
        width={700}
      >
        <Form form={form} onFinish={registerReview} layout="vertical">
          <Form.Item name="rating" label="별점">
            <Rate
              onChange={() => {}}
              value={1}
              count={5} // 최대 별 개수 설정
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="리뷰 내용"
            rules={[{ required: true, message: "리뷰 내용을 입력해주세요" }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              작성 완료
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* <Modal
        title="메시지 보내기"
        visible={isMessagingVisible}
        onCancel={() => setIsMessagingVisible(false)}
        footer={null}
        width={700}
      >
        <ChatComponent
          currentUser={userRole === "expert" ? project.manager : project.client}
          expertId={project.manager}
        />
      </Modal> */}

      <Modal
        title="작업 수정"
        visible={isEditTaskModalVisible}
        onCancel={() => setIsEditTaskModalVisible(false)}
        footer={null}
        getContainer={false}
        zIndex={1050}
      >
        <Form
          form={form}
          onFinish={handleEditTask}
          layout="vertical"
          initialValues={
            editingTask
              ? {
                  title: editingTask.title,
                  description: editingTask.description,
                  dueDate: moment(editingTask.deadline),
                  assignedTo: editingTask.assignee.username,
                }
              : {}
          }
        >
          <Form.Item
            name="title"
            label="작업 제목"
            rules={[{ required: true, message: "작업 제목을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="작업 설명"
            rules={[{ required: true, message: "작업 설명을 입력해주세요" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="dueDate"
            label="마감일"
            rules={[
              { required: true, message: "마감일을 선택해주세요" },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const today = dayjs().startOf("day");
                  const inputDate = dayjs(value, "YYYY-MM-DD", true);

                  if (inputDate.isValid() && inputDate.isAfter(today)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("현재 날짜 이후로 입력해주세요.")
                  );
                },
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
              disabledDate={(current) =>
                !!current && current < dayjs().startOf("day")
              }
            />
          </Form.Item>
          <Form.Item name="assignedTo" label="담당자">
            <Input disabled value={editingTask?.assignee.username} />
          </Form.Item>
          <Form.Item
            name="attachments"
            label="첨부파일"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
          >
            <Upload
              fileList={fileList}
              action={""}
              name="file"
              listType="text"
              customRequest={() => {}}
              onChange={(e) => {
                if (e.file.status === "uploading") {
                  handleChangeFiles(e);
                }
              }}
            >
              <Button icon={<UploadOutlined />}>파일 선택</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              수정 완료
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectDetailPage;
