/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import SurveyFormItem from "../../../components/survey/formItem/SurveyFormItem";
import {
  SurveyData,
  SurveyModel,
  SurveySectionModel,
} from "../../../models/requestApplication/RequestApplicationModel";
import RequestApplication from "../RequestApplication";
import RequestApplicationBanner from "./RequestApplicationBanner";
import styles from "./serviceRequestDocPresenter.module.scss";

interface Props {
  surveyModel: SurveySectionModel;
  currentFormData: { [key: string]: { [key: string]: string | string[] } };
  currentFormFileData: { [key: string]: { [key: string]: File[] } };
  isPrev: boolean;
  isNext: boolean;
  isSubmitable: boolean;
  formRef: React.RefObject<HTMLFormElement>;
  onChangeOption: (
    sectionTitle: string,
    questionTitle: string,
    changedValue: string | string[]
  ) => void;
  onChangeNextSection: (section: SurveySectionModel) => void;
  onClickMove: (type: "next" | "prev") => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onChangeFile: (
    sectionTitle: string,
    questionTitle: string,
    files: File[]
  ) => void;
}

export default (props: Props) => {
  const { surveyModel } = props;
  return (
    <>
      <RequestApplicationBanner />
      <section className="bg-light-md">
        <div className="container page py-120">
          <div className="form-wrap col-md-7 mx-auto">
            <h3 className="mb-4 text-dark">의뢰 요청 정보</h3>
            <div className="infoBox-highlight mb-4">
              <span className="material-symbols-outlined">info</span>
              <div className="d-flex gap-3 column-sm">
                <span>
                  <strong className="me-2">고객센터</strong>070-7537-2587
                </span>
                <span>
                  <strong className="me-2">E-mail</strong>ddk@ddukeong.com
                </span>
              </div>
            </div>
            <form className="row g-3" onSubmit={props.onSubmit}>
              <div className="col-md-6 pb-4">
                <label htmlFor="validationCustom01" className="form-label">
                  이메일<span className="text-danger ms-2">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="validationCustom01"
                  placeholder="이메일을 입력해주세요"
                  name="email"
                  required
                />
                <div className="invalid-feedback">필수 입력 정보입니다.</div>
              </div>
              <div className="col-md-6 pb-4">
                <label htmlFor="validationCustom02" className="form-label">
                  성함<span className="text-danger ms-2">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationCustom02"
                  placeholder="성함을 입력해주세요"
                  required
                />
                <div className="invalid-feedback">필수 입력 정보입니다.</div>
              </div>
              <div className="col-md-12 pb-4">
                <label htmlFor="validationCustom03" className="form-label">
                  연락처<span className="text-danger ms-2">*</span>
                </label>
                <p className="body2">
                  용역 의뢰 확인 후, 영업일 1-2일 내에 뚜껑설계 담당자가
                  유선으로 연락드립니다.
                </p>
                <div className="input-group col-md-6 has-validation">
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustom03"
                    placeholder="연락처를 입력해주세요"
                    required
                  />
                  <div className="invalid-feedback">필수 입력 정보입니다.</div>
                </div>
              </div>
              <div className="col-md-12 pb-4">
                <label htmlFor="validationCustom04" className="form-label">
                  의뢰 분야<span className="text-danger ms-2">*</span>
                </label>
                <div className="col-12 check-wrap">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check-category1"
                      value="option1"
                    />
                    <label className="form-check-label" htmlFor="check-category1">
                      수의계약 설계
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check-category2"
                      value="option2"
                    />
                    <label className="form-check-label" htmlFor="check-category2">
                      설계변경
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check-category3"
                      value="option3"
                    />
                    <label className="form-check-label" htmlFor="check-category3">
                      계약서류(계약내역서, 기성내역서, 준공내역서)
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check-category4"
                      value="option4"
                    />
                    <label className="form-check-label" htmlFor="check-category4">
                      적산
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check-category5"
                      value="option5"
                    />
                    <label className="form-check-label" htmlFor="check-category5">
                      공내역서 단가 작업
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check-category6"
                      value="option6"
                    />
                    <label className="form-check-label" htmlFor="check-category6">
                      기타 용역
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-12 pb-4">
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="Textarea1"
                    placeholder="의뢰하실 용역을 선택(중복가능)하고 필요하신 의뢰사항을 작성해주시면 빠른 용역수주가 가능합니다."
                    rows={6}
                  ></textarea>
                </div>
              </div>
              <div className="mo-fixed-bottom col-12 py-3">
                <button
                  className="btn btn-primary-soft btn-lg w-100 right-icon mx-auto justify-content-center"
                  type="submit"
                >
                  등록완료
                  <span className="material-symbols-outlined ms-2">
                    arrow_right_alt
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
