import { SearchOutlined } from "@ant-design/icons";
import "./search.scss";
import React, { useEffect, useRef, useState } from "react";
import AiSearchInput from "../../components/AiSearchInput/AiSearchInput";
import SearchResult from "../../components/searchResult/SearchResult";

export default function Search() {
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  useEffect(() => {
    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (stickyTopElement && searchContainerRef.current) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchContainerRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };
    // 초기 높이 설정
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  async function onSearchSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent,
    result: string
  ) {
    try {
      // 검색어 전달 후 UI 업데이트
      setSearchValue(result);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section className="search-container">
      <div className="overlay"></div>
      {searchValue ? (
        <SearchResult firstSearchValue={searchValue} />
      ) : (
        <div className="search-content" ref={searchContainerRef}>
          <h1>
            건설업의 미래가 시작되는 곳 <b>뚜껑설계 AI</b>
          </h1>
          <div className="search-category">
            <button className="search-category-item active">
              <span className="ant-design-icon">
                <SearchOutlined />
              </span>
              <span className="search-category-item-text">기본 웹 검색</span>
            </button>
            <button className="search-category-item">
              <span className="ant-design-icon">
                <SearchOutlined />
              </span>
              <span className="search-category-item-text">
                일위대가 자동생성
              </span>
            </button>
            <button className="search-category-item">
              <span className="ant-design-icon">
                <SearchOutlined />
              </span>
              <span className="search-category-item-text">
                건설 품셈 도우미
              </span>
            </button>
          </div>
          <AiSearchInput
            onSearchSubmit={onSearchSubmit}
            tooltipText="PRO 버튼을 활성화하면 최적화된 답변을 받을 수 있습니다."
          />
        </div>
      )}
    </section>
  );
}
