import React from "react";
import { Input, Button, Card, Avatar, List, Rate } from "antd";
import { RightOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./ServiceRequest.module.scss";
import { useState } from "react";
import { useEffect } from "react";
import { serviceApi } from "../../config/serviceApi";
import { ExternalProjectList } from "../../components/ExternalProjectList";
import { ExpertModel } from "../../models/service/ExpertModel";
import { ServiceCategoryModel } from "../../models/service/ServiceModel";

const { Search } = Input;

const ServiceRequest: React.FC = () => {
  const [categoriesAll, setCategoriesAll] = useState<ServiceCategoryModel[]>(
    []
  );
  const [categories, setCategories] = useState<ServiceCategoryModel[]>([]);
  const [experts, setExperts] = useState<ExpertModel[]>([]);

  useEffect(() => {
    async function fetch() {
      const responseByCategories = await serviceApi.getServiceCategories();
      setCategoriesAll(responseByCategories.data);
      setCategories(responseByCategories.data);
      const responseByExperts = await serviceApi.getExperts();
      setExperts(responseByExperts.data);
    }
    fetch();
  }, []);

  const getIcon = (icon: string) => {
    switch (icon) {
      case "drawing":
        return "📐";
      case "design":
        return "📑";
      case "change":
        return "🔄";
      case "list":
        return "📊";
      case "contract":
        return "📄";
      case "complete":
        return "🏗️";
      case "payment":
        return "💰";
      case "estimate":
        return "🧮";
    }
  };

  const onSearch = (value: string) => {
    if (value === "") {
      setCategories(categoriesAll);
      return;
    }
    setCategories(
      categories.filter(
        (category) =>
          category.title.includes(value) || category.description.includes(value)
      )
    );
  };

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <h1 className={styles.title}>전문가급 용역 서비스 플랫폼</h1>
        <p className={styles.description}>
          건설, 엔지니어링, 설계 전문가와 의뢰인을 연결하는 최적의 솔루션
        </p>
        {/* <Search
          placeholder="원하는 용역 서비스를 검색하세요"
          onSearch={onSearch}
          className={styles.searchBar}
          size="large"
        /> */}
      </main>

      <section className={styles.categories}>
        <h2>전문 서비스 카테고리</h2>
        <div className={styles.categoryGrid}>
          {categories.map((category, index) => (
            <Card key={index} className={styles.categoryItem}>
              <div className={styles.categoryContent}>
                <span className={styles.categoryIcon}>
                  {getIcon(category.icon)}
                </span>
                <h3>{category.title}</h3>
                <p style={{ minHeight: 88 }}>{category.description}</p>
              </div>
              <Link to={`/service/${category.id}`}>
                <Button type="primary" className={styles.detailButton}>
                  자세히 보기 <RightOutlined />
                </Button>
              </Link>
            </Card>
          ))}
        </div>
      </section>

      <section className={styles.serviceStatus}>
        <ExternalProjectList />
      </section>

      <section className={styles.experts}>
        <h2>추천 전문가</h2>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={experts}
          renderItem={(expert) => (
            <List.Item>
              <Link to={`/experts/${expert.id}`}>
                <Card className={styles.expertCard} hoverable>
                  <Card.Meta
                    avatar={
                      <Avatar src={expert.thumbnail} icon={<UserOutlined />} />
                    }
                    title={expert.nickname}
                    description={
                      <>
                        <div>{JSON.parse(expert.specialty).join(",")}</div>
                        <Rate disabled defaultValue={expert.rating} />
                      </>
                    }
                  />
                </Card>
              </Link>
            </List.Item>
          )}
        />
        <div className={styles.expertLinkContainer}>
          <Link to="/experts">
            <Button
              type="primary"
              size="large"
              className={styles.viewAllExpertsButton}
            >
              전체 전문가 보기 <RightOutlined />
            </Button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default ServiceRequest;
