import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import "./searchResult.scss";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AiSearchInput from "../AiSearchInput/AiSearchInput";
import { aiApi } from "../../config/aiApi";
import MarkdownTyper from "../MarkdownTyper";
import { AiChatCompletion, Message } from "../../models/aiSearchModel";
import SearchResultAside from "./SearchResultAside";
import SearchResultCitations from "./SearchResultCitations";

interface SearchResultProps {
  firstSearchValue: string;
}

export default function SearchResult({ firstSearchValue }: SearchResultProps) {
  const [searchValue, setSearchValue] = useState<string>(firstSearchValue);
  const [searchResult, setSearchResult] = useState<AiChatCompletion | null>();
  const [searchMessages, setSearchMessages] = useState<Message[]>([
    { role: "user", content: firstSearchValue },
  ]);
  const searchResultRef = useRef<HTMLDivElement | null>(null);

  const fetchData = useCallback(
    async (result: string) => {
      if (!result) {
        return;
      }
      console.log("searchMessages", searchMessages);
      if (searchMessages[searchMessages.length - 1].role === "assistant") {
        return;
      }
      const res = await aiApi.chatCompletions(searchMessages);
      if (res && res.choices) {
        const assistantMessage = {
          role: "assistant",
          content: res.choices[0].message.content,
        };
        setSearchMessages((prevMessages) => [
          ...prevMessages,
          assistantMessage,
        ]);
      }
      setSearchResult(res);
      console.log("res", res);
    },
    [searchMessages]
  );

  useEffect(() => {
    const updateHeight = () => {
      const stickyTopElement = document.querySelector(".sticky-top");
      if (searchResultRef.current && stickyTopElement) {
        const stickyHeight = stickyTopElement.clientHeight;
        searchResultRef.current.style.minHeight = `calc(100vh - ${stickyHeight}px)`;
      }
    };
    updateHeight();
    fetchData(firstSearchValue);
  }, [fetchData, firstSearchValue]);

  useEffect(() => {
    if (searchValue !== firstSearchValue) {
      setSearchMessages((prevMessages) => [
        ...prevMessages,
        { role: "user", content: searchValue },
      ]);
    }
  }, [searchValue, firstSearchValue]);

  function onSearchSubmit(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent | null,
    result: string
  ) {
    setSearchValue(result);
    fetchData(result);
  }

  return (
    <div className="search-result bg-light-md" ref={searchResultRef}>
      <SearchResultAside />
      <div className="search-result-content">
        <div className="search-result-content-body">
          <div className="search-result-content-body-item-list">
            <div className="search-result-content-body-title">
              <span className="search-item-title">{searchValue}</span>
            </div>
            <div className="search-result-content-body-item">
              <span className="search-result-content-body-item-title">
                <SearchOutlined />
                <span>답변</span>
              </span>
              <span className="search-item">
                {!searchResult && (
                  <span className="ant-design-icon">
                    <LoadingOutlined />
                  </span>
                )}
                {searchResult &&
                  searchResult.choices.map((choice, index) => (
                    <MarkdownTyper
                      key={index}
                      markdown={choice.message.content}
                    />
                  ))}
              </span>
            </div>
          </div>
          <div className="search-result-content-body-citations">
            <SearchResultCitations citations={searchResult?.citations || []} />
          </div>
        </div>
        <div className="search-result-content-footer">
          <AiSearchInput
            onSearchSubmit={onSearchSubmit}
            placeholder="추가 질문을 입력해주세요. 예) 추가적으로 알려줄 수 있나요?"
            tooltipText="PRO 버튼을 활성화하면 최적화된 답변을 받을 수 있습니다."
          />
        </div>
      </div>
    </div>
  );
}
