import axios from "axios";
import { aiApiKey } from "../models/apiKey";
import GlobalEnvironments from "../modules/globalEnvironment/GlobalEnvironment";
import { util } from "./util";
import { Cookies } from "../constants/Cookies";
import { Message } from "../models/aiSearchModel";

export const aiApi = {
  chatCompletions: async (messages: Array<Message>) => {
    const query = {
      model: "llama-3.1-sonar-large-128k-online",
      max_tokens: 5000,
      messages,
    };

    const response = await fetch("https://api.perplexity.ai/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: aiApiKey,
      },
      body: JSON.stringify(query),
    });

    if (!response.ok) {
      throw new Error("API 요청 실패", { cause: response });
    }

    return response.json();
  },
  getLinkPreview: async (url: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    console.log("url", url);
    return (
      await axios.get(`${GlobalEnvironments.getApiUrl()}dk_ai/crawl-og-tags`, {
        params: {
          url,
        },
        ...config,
      })
    ).data;
  },
};
